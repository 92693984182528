var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Per Page",
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "size": "sm",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getTransaksi();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
        _vm.getTransaksi();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Halaman",
      "label-for": "hal"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "fill",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.isFinance || _vm.isOwner ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function callback($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function callback($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Daftar yang sudah masuk ke akun dan kas",
      "label-for": "is_accounted"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_accounted",
      "options": _vm.optis_accounted
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_accounted,
      callback: function callback($$v) {
        _vm.is_accounted = $$v;
      },
      expression: "is_accounted"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function callback($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isCabang || _vm.isMarketing ? _c('b-row', [_c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Bayar",
      "label-for": "status_bayar"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "status_bayar",
      "options": _vm.optstatus_bayar
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.status_bayar,
      callback: function callback($$v) {
        _vm.status_bayar = $$v;
      },
      expression: "status_bayar"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "statuskirim"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "statuskirim",
      "options": _vm.optstatuskirim
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.statuskirim,
      callback: function callback($$v) {
        _vm.statuskirim = $$v;
      },
      expression: "statuskirim"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status",
      "label-for": "typeTransaksi"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "typeTransaksi",
      "options": _vm.opttypetransaksi
    },
    on: {
      "input": function input($event) {
        return _vm.getTransaksi();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.typeTransaksi,
      callback: function callback($$v) {
        _vm.typeTransaksi = $$v;
      },
      expression: "typeTransaksi"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('b-row', [_vm._l(_vm.items, function (item) {
    return [_c('b-col', {
      attrs: {
        "xl": "4",
        "lg": "4",
        "md": "6",
        "sm": "12"
      }
    }, [_c('b-card-actions', {
      attrs: {
        "collapsed": "",
        "action-collapse": "",
        "border-variant": "primary",
        "title": item.no_invoice
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "xl": "12",
        "lg": "12",
        "md": "12",
        "sm": "12"
      }
    }, [_c('b-card-text', [_c('b', [_vm._v("Detail Konsumen")])]), _c('hr'), _c('b-card-text', [_vm._v(_vm._s(item.alamat ? item.alamat.customer_nama + " ( " + item.alamat.customer_no_hp + " ) " : null))]), _c('b-card-text', [_vm._v(" " + _vm._s(item.alamat.alamat ? item.alamat.alamat : null) + " ")]), _c('b-card-text', [_vm._v(" " + _vm._s(item.alamat.desa ? item.alamat.desa.name + "," : null) + " " + _vm._s(item.alamat.kecamatan ? item.alamat.kecamatan.name + "," : null) + " " + _vm._s(item.alamat.kab_kota ? item.alamat.kab_kota.name + "," : null) + " " + _vm._s(item.alamat.provinsi ? item.alamat.provinsi.name : null) + " ")]), _c('hr')], 1), _c('b-col', {
      staticClass: "mt-1",
      attrs: {
        "xl": "12",
        "lg": "12",
        "md": "12",
        "sm": "12"
      }
    }, [_c('b-card-text', [_c('b', [_vm._v("Rincian (" + _vm._s(item.rincian.length) + ")")])]), _c('hr'), _vm._l(item.rincian ? item.rincian : [], function (rincian, index) {
      return [_c('b-card-text', [_vm._v(" " + _vm._s(index + 1 + ". " + rincian.nama + " - " + rincian.varian + " (" + rincian.qty + " " + (rincian.satuan ? rincian.satuan.satuan : " Jasa") + ")") + " ")])];
    }), _c('hr'), _c('b-button', {
      attrs: {
        "variant": "success"
      },
      on: {
        "click": _vm.hubadmin
      }
    }, [_vm._v("Selesai")])], 2)], 1)], 1)], 1)];
  })], 2), _c('b-modal', {
    attrs: {
      "title": "Pilih Terapis",
      "centered": "",
      "size": "lg",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModalTerapis,
      callback: function callback($$v) {
        _vm.showModalTerapis = $$v;
      },
      expression: "showModalTerapis"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingTerapis
    }
  }, [_c('b-table', {
    attrs: {
      "items": _vm.listTerapis,
      "fields": _vm.fieldsTerapis,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(nama_lengkap)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.nama_lengkap) + " ")];
      }
    }, {
      key: "cell(no_hp)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.no_hp) + " ")];
      }
    }, {
      key: "cell(alamat)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.alamat) + " ")];
      }
    }, {
      key: "cell(on_progress_orders)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.on_progress_orders.length) + " ")];
      }
    }, {
      key: "cell(completed_orders)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.completed_orders.length) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-button', {
          staticClass: "statistics-item-action mt-2",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.pilihTerapis(row.item);
            }
          }
        }, [_vm._v(" Pilih ")])];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }